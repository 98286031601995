DrawerJs.texts = {
  'Add Drawer': 'Add Drawer',
  'Insert Drawer': 'Insert Drawer',
  'Insert': 'Insert',
  'Free drawing mode': 'Free drawing mode',
  'SimpleWhiteEraser': 'SimpleWhiteEraser',
  'Eraser': 'Eraser',
  'Delete this canvas': 'Delete this canvas',
  'Are you sure want to delete this canvas?': 'Are you sure want to delete this canvas?',

  // canvas properties popup
  'Size (px)': 'Size (px)',
  'Position': 'Position',
  'Inline': 'Inline',
  'Left': 'Left',
  'Center': 'Center',
  'Right': 'Right',
  'Floating': 'Floating',
  'Canvas properties': 'Canvas properties',
  'Background': 'Background',
  'transparent': 'transparent',
  'Cancel': 'Cancel',
  'Save': 'Save',

  // Fullscreen plugin
  'Enter fullscreen mode': 'Enter fullscreen mode',
  'Exit fullscreen mode': 'Exit fullscreen mode',

  // shape context menu plugin
  'Bring forward': 'Bring forward',
  'Send backwards': 'Send backwards',
  'Bring to front': 'Bring to front',
  'Send to back': 'Send to back',
  'Duplicate': 'Duplicate',
  'Remove': 'Remove',

  // brush size plugin
  'Size:': 'Size:',

  // colorpicker plugin
  'Fill:': 'Fill:',
  'Transparent': 'Transparent',

  // shape border plugin
  'Border:': 'Border:',
  'None': 'None',

  // arrow plugin
  'Draw an arrow': 'Draw an arrow',
  'Draw a two-sided arrow': 'Draw a two-sided arrow',
  'Lines and arrows': 'Lines and arrows',

  // circle plugin
  'Draw a circle': 'Draw a circle',

  // line plugin
  'Draw a line': 'Draw a line',

  // rectangle plugin
  'Draw a rectangle': 'Draw a rectangle',

  // triangle plugin
  'Draw a triangle': 'Draw a triangle',

  // polygon plugin
  'Draw a Polygon': 'Draw a Polygon',
  'Stop drawing a polygon': 'Stop drawing a polygon (esc)',
  'Click to start a new line': 'Click to start a new line',

  // text plugin
  'Draw a text': 'Draw a text',
  'Click to place a text': 'Click to place a text',
  'Font:': 'Font:',

  // movable floating mode plugin
  'Move canvas': 'Move canvas',

  // base shape
  'Click to start drawing a ': 'Click to start drawing a '
};
